import Api from "./Api";

/**
 * @typedef {Rest}
 */
export default class Rest extends Api {
	/**
	 * @type {String}
	 */
	static resource = "";

	/**
	 * @type {String}
	 */
	id = "id";

	/**
	 * @param {String} resource
	 * @param {Object} options
	 * @param {Object} http
	 */
	constructor(resource, options = {}, http = null) {
		super(Rest.normalize(Rest.base, resource), options, http);
	}

	/**
	 * @return {this}
	 */
	static build() {
		return new this(this.resource);
	}

	setHttp(newHttp) {
		this.http = newHttp;
		return this;
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	create(record) {
		return record.id ? this.put(`/`, record) : this.post(`/`, record);
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	send(record) {
		return this.post("", record);
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	update(record) {
		return this.put(`/`, record);
	}

	/**
	 * mesma funcao de update, apenas nomenclatura
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	save(record) {
		return this.patch(`/`, record);
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	destroy(record) {
		return this.delete(`?id=${this.getId(record)}`);
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	search(record) {
		return this.get(`/?${this.queryString(record)}`);
	}

	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	show(record, filters = null) {
		return this.get(`/${this.getId(record)}`, filters);
	}
	/**
	 * @param {Object} record
	 * @returns {*|PromiseLike<T | never>|Promise<T | never>}
	 */
	midia(record, filters = null) {
		return this.getFile(`/${this.getId(record)}`, filters);
	}

	/**
	 * @param {String|Object} record
	 * @returns {String}
	 */
	getId(record) {
		if (typeof record === "object") {
			return record[this.id];
		}
		return String(record);
	}

	queryString(obj = {}) {
		return Object.keys(obj)
			.map(function(key) {
				if (
					obj &&
					obj[key] &&
					typeof obj[key] == "object" &&
					obj[key].length
				) {
					let url = [];
					for (let index = 0; index < obj[key].length; index++) {
						const objeto = obj[key][index];
						Object.keys(objeto).map(function(keyObjeto) {
							let valorObjeto = objeto[keyObjeto];

							url.push(
								`${key}[${index}][${keyObjeto}]=${valorObjeto}`
							);
						});
					}
					return url.join("&");
				} else {
					let url = `${key}=${obj[key]}`;
					return url;
				}
			})
			.join("&");
	}

	// Testando novo queryString com tratamento de array
	queryStringV2(obj = {}) {
		return Object.keys(obj)
			.map((key) => {
			if (Array.isArray(obj[key])) {
				if (obj[key][0] && typeof obj[key][0] === "object") {
				return obj[key]
					.map((item, index) =>
					Object.keys(item)
						.map(
						(subKey) =>
							`${encodeURIComponent(key)}[${index}][${encodeURIComponent(
							subKey
							)}]=${encodeURIComponent(item[subKey])}`
						)
						.join("&")
					)
					.join("&");
				} else {
				return obj[key]
					.map(
					(value) =>
						`${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`
					)
					.join("&");
				}
			} else {
				return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
			}
			})
			.join("&")
	}
}
