<template>
  <v-container fluid :class="projectId ? ' pa-0' : ''">
    <template v-if="!isHiddenRoute">
      <v-row>
        <v-col xs="12" v-if="!projectId">
          <v-subheader>
            <h2>Caderno de campo</h2>
          </v-subheader>
        </v-col>
      </v-row>
      <v-card :class="!projectId ? 'elevation-1' : 'elevation-0 mt-8'">
        <v-tabs v-model="tab" show-arrows>
          <v-tabs-slider :color="$vuetify.theme.primary"></v-tabs-slider>
            <v-tab :style="{ color: $vuetify.theme.primary }" v-for="(item, i) of tabs" :key="i" :to="item.route">
              {{ item.title }}
            </v-tab>
        </v-tabs>

      </v-card>
      
    </template>
    <router-view
      :class="projectId ? 'ma-4' : ''"
      :apiaries="apiaries"
      :suppliers="suppliers"
      :projectId="projectId"
      :routeName="routeName"
      :supplier="supplier"
      :apiaryGroup="apiaryGroup"
      @updateSupplier="updateSupplier"
      @updateApiaryGroup="updateApiaryGroup"
    ></router-view>
  </v-container>
</template>

<script>
import { bus } from "../../../../../../src/main";


export default {
  name: "FieldNotes",
  props: {
    routeName: { type: String, default: "/field-notes" },
    type: { type: String, default: "RECORD" },
    projectId: { type: Number, default: null },
    importToProject: { type: Boolean, default: false },
  },
  computed: {
    isHiddenRoute() {
      const hiddenRoutes = [
        "/field-notes",
        "/field-notes/select-group",
        "/field-notes/select-supplier",
      ];
      return hiddenRoutes.includes(this.$route.path);
    },
  },
  data() {
    return {
      dialog: false,
      tab: null,
      supplier: null,
      apiaryGroup: null,
      componentName: "",
      tabs: [
        {
          title: "Mel",
          route: this.routeName + "/honey-appointments",
        },
        {
          title: "Cera",
          route: this.routeName + "/beeswax-appointments",
        },
        {
          title: "Limpeza / Manejo",
          route: this.routeName + "/cleaning-records",
        },
        {
          title: "Alimentação artificial",
          route: this.routeName + "/feed-records",
        },

        {
          title: "Tratamento de doenças",
          route: this.routeName + "/disease-treatment-records",
        },
        {
          title: "Integração de enxames",
          route: this.routeName + "/bee-swarm-captures",
        },
      ],
      apiaries: [],
      suppliers: [],
    };
  },
  created() {
    bus.$on("openDialog", (data) => {
      this.dialog = !this.dialog;
      this.componentName = data.params.componentName;
    });

    bus.$on("closeDialog", () => {
      this.dialog = false;
    });
  },
  async mounted() {
    await this.getApiary();
    await this.getBeekpeers();
  },
  methods: {
    updateSupplier(newValue) {
      this.supplier = newValue;
    },

    updateApiaryGroup(newValue) {
      this.apiaryGroup = newValue;
    },

    async getApiary() {
      try {
        let response = await this.minamelServices.ApiaryServiceV2().getAll();

        this.apiaries = response.data;
      } catch (err) {
        console.error(err);
      }
    },
    async getBeekpeers() {
      try {
        const response = await await this.minamelServices
          .SupplierServiceV2()
          .getBeekpeers();

        this.suppliers = response.data;
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
