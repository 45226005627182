<template>
  <v-container fluid>
    <v-row row>
      <v-col xs="12" lg12 md="12">
        <v-subheader>
          <h2>Selecionar Produtor</h2>
        </v-subheader>
      </v-col>
    </v-row>

    <v-row row justify-space-between>
      <v-col xs="7" md="5">
        <v-text-field
          v-model="filter.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>
    </v-row>

    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
      
    >
    <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              @click="selectSupplier(item)"
              class="mx-1"
              icon
              text
              v-on="on"
            >
              <v-icon color="#707070">visibility</v-icon>
            </v-btn>
          </template>
          <span>Visualizar Caderno</span>
        </v-tooltip>
      </template>
      
    </v-data-table>

    <div>
      <v-card class="pa-3">
        <v-row wrap>
          <v-col xs="12" md="12" class="text-xs-center">
            <v-pagination
              v-model="table.pagination.page"
              :length="table.pagination.pages"
              v-bind:disabled="table.disabled"
              :total-visible="table.pagination.totalVisible"
              prev-icon="arrow_left"
              next-icon="arrow_right"
              primary
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "SelectSupplierFieldNotes",
  props: {
    supplier: { type: Number, default: null },
    apiaryGroup: { type: Number, default: null },
  },
  data() {
    return {
      expandFilters: false,
      filter: {
        term: "",
        active: "",
      },
      isImporting: false,
      userData: {},
      systemId: null,
      systemData: null,
      token: null,
      table: {
        headers: [
          { text: "Produtor" ,value: "name", width: "200px"},
          { text: "Apiário", value: "apiary", width: "200px" },
          { text: "Colméias Produtivas", value: "totalHiveCount", width: "200px" },
          { text: "", value: "actions", align: 'right', sortable: false, width: "100px" },
        ],
        items: [],
        loading: false,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },

  async mounted() {
    this.filter.term = this.$functions.getSessionStorageSearchTerm("supplier");
    await this.getData();
  },

  methods: {
    selectSupplier(newValue) {
      this.$emit('updateSupplier', newValue.import_key);
      this.$router.push({
        path: "/field-notes/honey-appointments",
      });
    },
    
    async getData() {
      this.$functions.setSessionStorageSearchTerm("supplier", this.filter.term);

      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          active: this.filter.active ? this.filter.active : "",
          term: this.filter.term ? this.filter.term : "",
          apiaryGroup: this.$props.apiaryGroup,
        };

        const res = await this.minamelServices
          .SupplierServiceV2()
          .getAll(params);

        this.table.items = res.data;
        this.table.pagination.pages = res.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },
  },
  watch: {
    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>
