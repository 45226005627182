<template>
    <v-row row wrap>
        <v-col xs="12" md="12" pa-3>
            <v-row justify="space-between">
                <span class="required grey--text">
                    * Preenchimento obrigatório.
                </span>

                <div>
                    <v-btn color="blue" style="color: white;margin-right: 5px;" @click="openModal">
                        Importar Template
                    </v-btn>
                    <v-btn color="primary" @click="triggerFileInput">
                        Importar Arquivo
                    </v-btn>
                </div>
            </v-row>

            <input ref="fileInput" type="file" @change="handleFileUpload" accept=".docx" style="display: none;" />
        </v-col>

        <v-col xs="12" md="8" pa-3>
            <v-text-field label="Nome do formulário" v-model="formStore.state.name"
                :rules="[$functions.validationRequiredField, $functions.noSpecialCharacters]" required
                class="required" />
        </v-col>

        <!-- Modal -->
        <v-dialog v-model="dialog" max-width="1000px">
            <v-card>
                <v-card-title>Selecione um Template</v-card-title>

                <v-data-table :headers="table.headers" :items="table.items" :loading="table.loading" class="elevation-1"
                    hide-default-footer>
                    <template v-slot:[`item.layout`]="{ item }">
                        <span>{{ layouts.filter((i) => i.value === item.layout)[0]?.text ?? "" }}</span>
                    </template>
                    <template v-slot:[`item.fields`]="{ item }">
                        <span>{{ item.fields.length }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="importTemplate(item)">
                                    <v-icon color="#707070">check</v-icon>
                                </v-btn>
                            </template>
                            <span>Importar</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <div>
                    <v-card class="pa-3">
                        <v-row wrap>
                            <v-col xs="12" md="12" class="text-xs-center">
                                <v-pagination v-if="table.pagination.perPage != -1" v-model="table.pagination.page"
                                    :length="table.pagination.pages" v-bind:disabled="table.disabled"
                                    :total-visible="table.pagination.totalVisible" prev-icon="arrow_left"
                                    next-icon="arrow_right" primary />
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import FormStore from '../../store/FormStore';
import TemplateStore from '@/plugins/minamel-lib/src/components/DocumentTemplates/store/TemplateStore';
import { extractQuestions, extractHtmlFromDocx } from '@/utils/file.js';

export default {
    name: "FormRegisterMainInfo",
    data() {
        return {
            dialog: false,
            formStore: new FormStore(),
            layouts: [],
            table: {
                headers: [
                    {
                        text: "Nome",
                        align: "left",
                        value: "title"
                    },
                    {
                        text: "Layout",
                        align: "left",
                        value: "layout"
                    },
                    {
                        text: "Quantidade de Campos",
                        align: "left",
                        value: "fields"
                    },
                    {
                        text: "",
                        value: "actions",
                        align: "right",
                        sortable: false
                    },
                ],
                items: [],
                loading: false,
                pagination: {
                    page: 1,
                    pages: 0,
                    perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
                    rowsPerPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
                    totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
                },
            },
        };
    },
    async mounted() {
        await this.getTemplates();

        const layouts = new TemplateStore();
        this.layouts = layouts.layoutList;
    },
    methods: {
        openModal() {
            this.dialog = true;
        },
        closeModal() {
            this.dialog = false;
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        async getTemplates() {
            try {
                this.table.loading = true;

                const params = {
                    page: this.table.pagination.page,
                    perPage: this.table.pagination.perPage,
                };

                const res = await this.minamelServices.DocumentTemplateService().get(params);

                this.table.items = res.data;
                this.table.pagination.pages = res.meta.last_page;
                this.table.loading = false;
            } catch (error) {
                this.table.loading = false;
            }
        },
        async handleFileUpload(event) {
            const file = event.target.files[0];

            if (file) {
                const html = await extractHtmlFromDocx(file);
                const questions = await extractQuestions(html, 'CHECKLIST');

                questions.map(async (quest) => {
                    this.$emit("addItemOnEnd", quest);
                })
            }
        },
        async importTemplate(item) {
            this.formStore.state.template_id = item.id;
            this.formStore.state.items = item.fields.map(item => ({
                ...item,
                config: JSON.parse(item.config),
            }));

            this.closeModal();
        }
    },
    watch: {
        "table.pagination.page": function () {
            this.getTemplates();
        },
    },
}
</script>