<template>
  <v-form>
    <v-card flat class="mb-12">
      <v-container>
        <form-response-summary />

        <v-row>
          <v-col>
            <h2 class="headline title">{{ formResponse.form_name }}</h2>

            <span
              class="float-right font-weight-bold"
              :class="onlyView ? 'blue--text' : 'red--text'"
            >
              ({{ onlyView ? "Modo de visualização" : "Modo de edição" }})
            </span>

            <span class="d-block required grey--text mt-4">
              * Preenchimento obrigatório.
            </span>
          </v-col>
        </v-row>

        <item-visualization
          v-for="(item, i) in formResponse.formFields"
          :item="item"
          :key="i"
          :onlyView="onlyView"
        />

        <v-divider></v-divider>

        <form-response-register-actions
          v-model="formResponse"
          :are-editting="!areViewing"
          @onSave="save"
        />
      </v-container>
    </v-card>
  </v-form>
</template>

<script>
import FormResponseRegisterActions from "../components/form_response_register/FormResponseRegisterActions.vue";
import FormResponseSummary from "../components/form_response_register/FormResponseSummary.vue";
import ItemVisualization from "../components/item_visualization/ItemVisualization.vue";

export default {
  components: {
    FormResponseSummary,
    FormResponseRegisterActions,
    ItemVisualization,
  },
  name: "FormResponseRegister",

  props: {
    onlyView: { type: Boolean, default: false },
  },

  data() {
    return {
      userData: null,
      formId: null,
      supplierId: null,
      formResponse: {},
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: null,
        y: "bottom",
        multiLine: false,
      },
    };
  },

  async mounted() {
    this.projectFormResponseId = this.$route.params.projectFormResponseId;
    this.formResponseId = this.$route.params.formResponseId;

    console.log("this.formResponse: ", this.formResponse);

    await this.getData();
  },

  methods: {
    async getData() {
      try {
        const response = await this.minamelServices
          .ProjectFormResponseService()
          .show(this.projectFormResponseId, this.formResponseId);

        this.formResponse = response.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.$forceUpdate();
      }
    },

    async save() {
      const alertConfirm = await this.alertConfirm("Atenção", "warning");

      if (!alertConfirm) {
        return;
      }

      this.formResponse.formFields.forEach((item) => {
        if (item.type === "TEXTBLOCK") {
          item.response = item.help_text;
        }
      });

      if (this.formResponse.id || this.formResponse.import_key) {
        this.update();
      } else {
        this.create();
      }
    },

    alertConfirm(ask, type) {
      let returnAlert = this.$swal
        .fire({
          title: `${ask}`,
          type: type,
          text: "O preenchimento deste formulário é de responsabilidade do produtor/agregado. Deseja realmente alterá-lo?",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return false;
          }
          return true;
        });
      return returnAlert;
    },

    async create() {
      try {
        const data = {
          form_id: this.formResponse.form_id,
          project_id: this.formResponse.project_id,
          supplier_import_key: this.userData.import_key,
          answered_by_user_import_key: this.userData.import_key,
          form_fields: this.formResponse.formFields,
          id: this.formResponse.id,
          status: this.formResponse.status,
          import_key: this.formResponse.import_key,
        };

        const response = await this.minamelServices
          .FormResponseService()
          .store(data);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Formulário preenchido com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.go(-1);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao realizar o cadastro.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async update() {
      try {
        const data = {
          form_id: this.formResponse.form_id,
          project_id: this.formResponse.project_id,
          supplier_import_key: this.formResponse.supplier_id,
          form_fields: this.formResponse.formFields,
          id: this.formResponse.id,
          status: this.formResponse.status,
          import_key: this.formResponse.import_key,
        };

        const response = await this.minamelServices
          .FormResponseService()
          .update(this.formResponse.id, data);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Formulário alterado com sucesso!";
          this.$root.$SnackBar.show(this.snackbar);

          this.$router.go(-1);
        }
      } catch (err) {
        console.error(err);
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao alterar os dados.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },
  },
};
</script>

<style></style>
