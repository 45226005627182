<template>
  <v-container fluid>
    <v-row row>
      <v-col xs="12" lg12 md="12">
        <v-subheader>
          <h2>Grupos de Apiário/Produtor</h2>
        </v-subheader>
      </v-col>
    </v-row>

    <v-row row justify-space-between>
      <v-col xs="7" md="5">
        <v-text-field
          v-model="filter.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>

      <v-col xs="3" md="2" class="text-xs-left">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn large color="normal" v-on="on" @click="toggleFilters">
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <span>Clique para selecionar os filtros</span>
        </v-tooltip>
      </v-col>

      <v-col></v-col>

      <v-col class="text-xs-right d-flex">
        <v-btn
          class="flex-one ml-auto"
          color="primary"
          large
          to="apiary-groups/register"
        >
          + Incluir
        </v-btn>
      </v-col>
      <!-- <v-col class="text-xs-right d-flex" v-show="someCondition">
        <div>
          <v-btn color="primary" @click="triggerFileInput">
            Importar Arquivo
          </v-btn>
          <input
            ref="fileInput"
            type="file"
            @change="handleFileUpload"
            accept=".xlsx"
            style="display: none"
          />
        </div>
      </v-col> -->
    </v-row>

    <v-expand-transition>
      <v-row row wrap v-show="expandFilters">
        <v-col md="12">
          <v-card class="elevation-1 pa-3 mb-4">
            <v-row row>
              <v-col xs="12">
                <v-label>Selecione os campos para fazer a busca</v-label>
              </v-col>
            </v-row>

            <v-row row wrap>
              <v-col xs="12" md="3" pa-3>
                <v-select
                  chips
                  deletable-chips
                  clearable
                  v-model="filter.active"
                  :items="[
                    { value: '1', text: 'Sim' },
                    { value: '0', text: 'Não' },
                  ]"
                  label="Ativo"
                  @change="getData"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:[`item.active`]="{ item }">
        <v-chip v-if="item.active == 1" color="green" text-color="white"
          >Ativo</v-chip
        >
        <v-chip v-else color="#5e636b" text-color="white">Inativo</v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :to="`/apiary-groups/edit/${item.id}`" icon v-on="on">
              <v-icon color="#707070">edit</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="remove(item.id)" icon v-on="on">
              <v-icon color="#707070">clear</v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div>
      <v-card class="pa-3">
        <v-row wrap>
          <v-col xs="12" md="12" class="text-xs-center">
            <v-pagination
              v-model="table.pagination.page"
              :length="table.pagination.pages"
              v-bind:disabled="table.disabled"
              :total-visible="table.pagination.totalVisible"
              prev-icon="arrow_left"
              next-icon="arrow_right"
              primary
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </v-container>
</template>

<script>
// import * as XLSX from "xlsx";

export default {
  name: "ApiaryGroups",
  data() {
    return {
      expandFilters: false,
      filter: {
        term: "",
        active: "",
      },
      table: {
        headers: [
          { text: "Status", value: "active", align: "left" },
          { text: "Grupo", value: "name" },
          { text: "Safra", value: "harvest_count" },
          {
            text: "",
            value: "actions",
            align: "right",
            sortable: false,
          },
        ],
        items: [],
        loading: false,
        // rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        pagination: {
          page: 1,
          perPage: this.CONSTANTS.integer.table.numberOfRowPerPage,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },
  async mounted() {
    this.filter.term =
      this.$functions.getSessionStorageSearchTerm("apiaryGroups");

    await this.getData();
  },

  methods: {
    // triggerFileInput() {
    //   this.$refs.fileInput.click();
    // },
    async getData() {
      this.$functions.setSessionStorageSearchTerm(
        "apiaryGroups",
        this.filter.term
      );

      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          active: this.filter.active ? this.filter.active : "",
          term: this.filter.term ? this.filter.term : "",
        };

        const res = await this.minamelServices
          .ApiaryGroupServiceV2()
          .getAll(params);

        res.data = res.data.map((group) => {
          const harvestCount = group.apiary.reduce((total, apiaryItem) => {
            return total + (apiaryItem.harvest ? apiaryItem.harvest : 0);
          }, 0);

          return {
            ...group,
            harvest_count: harvestCount,
          };
        });

        this.table.items = res.data;
        this.table.pagination.pages = res.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    // async handleFileUpload(event) {
    //   const file = event.target.files[0];

    //   if (file) {
    //     const data = await this.readExcelFile(file);
    //     const producers = this.mapExcelToProducers(data);

    //     producers.forEach(async (producer) => {
    //       await this.save(producer);
    //       this.$emit("addItemOnEnd", producer);
    //     });
    //   }
    // },

    // async readExcelFile(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //       const arrayBuffer = event.target.result;
    //       const workbook = XLSX.read(arrayBuffer, { type: "array" });
    //       const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    //       const data = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
    //       resolve(data);
    //     };
    //     reader.onerror = (error) => reject(error);
    //     reader.readAsArrayBuffer(file);
    //   });
    // },
    // mapExcelToProducers(data) {
    //   const headers = data[0];
    //   const rows = data.slice(1);

    //   return rows.map((row) => {
    //     return {
    //       nome: row[headers.indexOf("Nome")],
    //       cpf: row[headers.indexOf("CPF")],
    //       telefone: row[headers.indexOf("Telefone")],
    //       email: row[headers.indexOf("Email")],
    //       endereco: row[headers.indexOf("Endereço")],
    //     };
    //   });
    // },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices.ApiaryGroupServiceV2().delete(id);

      if (res) {
        await this.getData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },
  },
  watch: {
    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>
