<!-- eslint-disable vue/no-mutating-props -->
<template>
	<v-dialog v-model="active" persistent content-class="bs-none">
		<v-card class="mw-50">
			<v-card-title>
				<v-text-field
					label="Clique para adicionar um título"
					class="title text--primary w-100"
					v-model="observation.name"
					@focus="!clicked ? null : changeObservationNameHandler"
					:readonly="!clicked && observation.index"
					solo
					flat
				/>

				<span class="grey--text caption ml-auto mt-n3" v-if="observation.distanceToMainMarker">
					Distância até o apiário: {{ observation.distanceToMainMarker }}
				</span>
			</v-card-title>
			<v-card-text>
				<wysiwyg class="observation" v-if="clicked || !observation.index" v-model="observation.description" />

				<div v-html="observation.description" class="word-break" v-else/>
			</v-card-text>

			<v-card-actions>
				<v-btn
					class="mr-auto"
					v-if="(observation.index != null) && !blocked"
					@click="destroy"
					outline
					color="error"
				>
					Excluir
				</v-btn>
				<v-btn
					class="br-4 ml-auto"
					outline
					color="grey"
					@click="close"
					:class="!observation.index ? 'ml-auto': ''"
				>
					Fechar
				</v-btn>

				<v-btn
					color="success"
					depressed
					@click="save"
				>
					{{ (clicked || !observation.index) ? 'Salvar' : 'Editar'}}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import remarkIcon from "../icons/remark.svg";

export default {
	name: 'MarkerFormDialog',
	props: {
		active: Boolean,
		controller: Object,
		blocked: Boolean
	},
	data() {
		return {
			observation: {},
			serializedName: {},
			clicked: false
		};
	},
	watch: {
		active() {
			this.observation = this.controller.observation;

			this.serializeName();
		}
	},
	methods: {
		serializeName(toAdd = null, contentLength = 80, referUpdating = false) {
			const observation = toAdd || this.controller.observation;

			if (!observation || !observation.name) {
				return observation;
			}

			const name = observation.name;

			if (name.endsWith('...')) {
				return observation.name;
			}

			observation.unserializedName = name;
			let serializedName = '';

			if (name.length >= contentLength) {
				serializedName = name.slice(0, contentLength) + '...';
			} else {
				serializedName = name;
			}

			if (referUpdating) {
				return serializedName;
			}

			observation.name = serializedName;
			return observation.serializedName;
		},
		changeObservationUnserializedName(value) {
			this.observation.unserializedName = value;
		},
		changeObservationNameHandler() {
			this.observation.name = this.observation.unserializedName;
		},
		close() {
			this.clicked = false;
			this.$emit('close');
		},
		save() {
			if (this.observation && this.observation.index) {
				if (this.clicked) {
					this.update();
				} else {
					this.clicked = true;
					this.changeObservationNameHandler();
				}
			} else {
				this.create();
			}
		},
		destroy() {
			this.$emit('destroy', this.observation);
			this.close();
		},
		create(toAdd = null, referUpdating = true, setup = false) {
			const { Marker } = this.controller.google_tools.maps;

			toAdd = toAdd || this.observation;

			const toAddName = this.serializeName(toAdd, 12, referUpdating);

			const observation = new Marker({
				unserializedName: toAdd.unserializedName,
				draggable: false,
				position: this.controller.position,
				map: this.controller.map,
				label: {
					text: `${toAddName}`,
					className: 'google_maps_marker'
				},
				icon: {
					url: remarkIcon,
					// eslint-disable-next-line no-undef
					size: new google.maps.Size(toAddName.length * 8, 20),
					// The origin for this image is (0, 0).
					// eslint-disable-next-line no-undef
					origin: new google.maps.Point(0, 0),
					// The anchor for this image is the base of the flagpole at (0, 32).
					// eslint-disable-next-line no-undef
					anchor: new google.maps.Point(toAddName.length * 8, 20)
				},
				description: toAdd.description,
				name: toAdd.name
			});

			observation.lastPosition = observation.getPosition();
			observation.distanceToMainMarker = this.getDistanceToMainMarker(observation);

			observation.addListener('click', (event) => {
				this.$emit('edit', observation, event);
			});

			this.$emit('save', observation, referUpdating, setup);

			this.close();
		},
		getDistanceToMainMarker(observation) {
			const positionOne = observation.getPosition();
			const positionTwo = this.controller.marker.getPosition();

			return this.getDistanceBetweenTwoPoints(positionOne, positionTwo) || 0;
		},
		update() {
			this.observation.label.text = this.observation.name;

			this.observation.setTitle(this.observation.name);

			this.$emit('save', this.observation, true);
			this.close();
		},
		getDistanceBetweenTwoPoints (p1, p2) {
			const sphericalTool = this.controller.google_tools.maps.geometry.spherical;

			let distance = sphericalTool.computeDistanceBetween(
				p1,
				p2
			);
			// let distance = 10;
			return (distance / 1000).toFixed(2).toString().replace(".", ","); // dividing by 1000 to get Kilometers
		}
	}
};
</script>

<style>
.word-break {
	word-break: break-word;
}

.mw-50 {
	max-width: 50%;
	margin: 0 auto;
}

.w-100 {
	width: 100%;
}

.br-4 {
	border-radius: 4px;
}

.bs-none {
	box-shadow: none !important;
}

.google_maps_marker {
	background-color: white;
	color: rgba(0,0,0,0.7) !important;
	padding: 4px;
	border-radius: 4px;
	box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%) !important
}

.observation > div > div > .vw-btn-image {
	display: none !important;
}

.observation > div > div > .vw-btn-table {
	display: none !important;
}

.observation > div > div > .vw-btn-code {
	display: none !important;
}

.observation > div > div > .vw-btn-link {
	display: none !important;
}

.observation > div > div > .vw-btn-separator {
	display: none !important;
}
</style>
