<template>
	<v-card style="padding: 12px;">
    <!-- <v-card
        class="elevation-0"
        :class="!projectId ? 'ma-2' : 'ma-0 project-margin-adjustment'"
    > -->
        <v-form ref="form">
            <v-row row wrap>
                <!-- <v-col xs="12" md="12" pa-3>
                    <span class="required grey--text"
                        >* Preenchimento obrigatório.</span
                    >
                </v-col> -->

                <!-- <v-col xs="12" md="4" pa-3>
                    <v-autocomplete
                        :rules="[formRules.required]"
                        :cache-items="true"
                        chips
                        :deletable-chips="true"
                        v-model="register.supplier_id"
                        :items="suppliers"
                        item-text="name"
                        item-value="id"
                        label="Produtor"
                        @change="onChangeSupplier"
						disabled
                    >
                        <template slot="item" slot-scope="data">
                            {{ data.item.name }}
                            <span class="font-weight-bold">
                                - COD.
                                {{ data.item.id }}</span
                            >
                        </template>
                    </v-autocomplete>
                </v-col> -->

				<v-col xs="12" md="4" pa-3 pt-4 v-if="register.movement_type === 'OUTPUT'">
                    <v-text-field
                        precision="0"
                        type="string"
                        :rules="[formRules.required]"
                        v-model="register.buyer"
                        label="Comprador"
                        class="required"
                    />
                </v-col>

				<!-- TODO: SELECIONAR CORRETAMENTE O APIÁRIO -->
                <v-col xs="12" md="4" pa-3  v-if="register.movement_type === 'INPUT'">
                    <v-autocomplete
                        chips
                        :deletable-chips="true"
                        v-model="register.apiary_import_key"
                        :items="mutableApiaries"
                        item-text="name"
                        item-value="import_key"
                        label="Apiário"
						class="required"
                    />
                </v-col>

                <v-col xs="12" md="4" pa-3 pt-4>
                    <v-menu
                        v-model="datePickerMenu"
                        :close-on-content-click="false"
                        full-width
                        max-width="290"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                :rules="[formRules.required]"
                                :value="$functions.formatDate(register.date)"
                                clearable
                                label="Data"
                                readonly
                                v-on="on"
								class="required"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            locale="pt-br"
                            v-model="register.date"
                            @change="datePickerMenu = false"
                        ></v-date-picker>
                    </v-menu>
                </v-col>

                <!-- <v-col xs="12" md="4" pa-3>
                    <v-select
                        clearable
                        color="primary"
                        chips
                        deletable-chips
                        :items="qualityItems"
                        v-model="register.quality"
                        item-value="id"
                        item-text="name"
                        label="Qualidade"
                        ref="quality"
                        :rules="[formRules.required]"
						disabled
                    ></v-select>
                </v-col> -->

                <v-col xs="12" md="4" pa-3 pt-4>
                    <v-text-field
                        precision="0"
                        type="number"
                        :rules="[formRules.required]"
                        v-model="register.quantity"
						suffix="Kg"
                        label="Quantidade"
                        class="required"
                    />
                </v-col>

                <v-col xs="12" md="4" pa-3 pt-4>
                    <v-select
                        :rules="[formRules.required]"
                        v-model="register.movement_type"
                        :items="movementTypeOptions"
                        label="Tipo de movimento"
						disabled
                    />
                </v-col>

                <!-- <v-col
                    xs="12"
                    md="4"
                    pa-3
                    v-if="register.movement_type === 'OUTPUT'"
                >
                    <v-select
                        :rules="[formRules.required]"
                        v-model="register.destination_system_variable_id"
                        :items="systemVariables"
                        item-value="id"
                        item-text="value"
                        label="Destino do mel"
                        class="required"
						
                    />
                </v-col> -->
            </v-row>

            <v-divider></v-divider>
            <v-card-actions class="pa-3">
                <v-spacer></v-spacer>
                <v-btn style="color: white" color="green" @click="save"
                    >Salvar</v-btn
                >
                <v-btn
					color="error"
					flat
                    :to="`${routeName}/honey-appointments`"
                    >Cancelar</v-btn
                >
            </v-card-actions>
        </v-form>
    </v-card>
</template>
<script>
import jwt from "vue-jwt-decode";

export default {
	name: "HoneyAppoimentsRegister",
	props: {
		apiaries: { type: Array },
		suppliers: { type: Array },
		projectId: { type: Number, default: null },
		routeName: { type: String, default: "/field-notes" }
	},
	data () {
		return {
			userData: null,
			formRules: {
				required: (value) => !!value || "Campo obrigatório",
				validApiary: () =>
					!!this.register.supplier_id || "Selecione um produtor"
			},
			movementTypeOptions: [
				{ value: "INPUT", text: "ENTRADA DE MEL" },
				{ value: "OUTPUT", text: "SAÍDA DE MEL" }
			],
			qualityItems: [
				{ id: 1, name: "Convencional" },
				{ id: 2, name: "Orgânico" }
			],
			datePickerMenu: false,
			systemVariables: [],
			register: {},
			snackbar: {
				message: "",
				visible: false,
				color: "success",
				timeout: 6000,
				x: null,
				y: "bottom",
				multiLine: false
			},
			mutableApiaries: this.apiaries
		};
	},
	async mounted () {
		this.userData = (await sessionStorage.token)
			? jwt.decode(sessionStorage.token).userData
			: null;
		this.mutableApiaries = this.apiaries;

		this.getSystemVariables();

		this.id = this.projectId
			? this.$route.params.childrenId
			: this.$route.params.id;
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		async getData () {
			const response = await this.minamelServices
				.HoneyAppointmentService()
				.show(this.id);

			this.register = response.data;
			this.register.date = this.register.date.split("T")[0];
		},

		async getSystemVariables () {
			const params = { type: "HONEY_APPOINTMENT_DESTINATION" };

			const response = await this.minamelServices
				.SystemVariableService()
				.get(params);

			this.systemVariables = response.data;
		},

		async save () {
			let formValidate = this.$refs.form.validate();

			if (!formValidate) {
				this.snackbar.color = "warning";
				this.snackbar.message = "Verifique os campos em destaque.";
				this.$root.$SnackBar.show(this.snackbar);
				return;
			}

			if (this.id) {
				this.update();
				return;
			}

			this.create();
		},

		onChangeSupplier () {
			const filtered = this.apiaries.filter((obj) => {
				return this.register.supplier_id === obj.supplier_id;
			});

			if (filtered.length) {
				this.mutableApiaries = filtered;
				this.register.apiary_import_key = null;

				return;
			}

			if (!this.register.supplier_id) {
				this.mutableApiaries = this.apiaries;
				this.register.apiary_import_key = null;

				return;
			}

			this.mutableApiaries = [];
		},

		async create () {
			this.register.user_id = this.userData.id;
			this.register.type = "RECORD";

			if (this.projectId) {
				this.register.project_id = this.projectId;
			}

			try {
				const response = await this.minamelServices
					.HoneyAppointmentService()
					.create(this.register);

				if (response) {
					this.snackbar.color = "success";
					this.snackbar.message =
                        "Registro de mel incluído com sucesso!";
					this.$root.$SnackBar.show(this.snackbar);

					this.$router.push(`${this.routeName}/honey-appointments`);
				}
			} catch (err) {
				this.snackbar.color = "error";
				this.snackbar.message =
                    "Ocorreu um erro ao realizar o cadastro.";
				this.$root.$SnackBar.show(this.snackbar);
			}
		},

		async update () {
			try {
				const response = await this.minamelServices
					.HoneyAppointmentService()
					.update(this.id, this.register);

				if (response) {
					this.snackbar.color = "success";
					this.snackbar.message =
                        "Fluxo do mel alterado com sucesso!";
					this.$root.$SnackBar.show(this.snackbar);

					this.$router.push(`${this.routeName}/honey-appointments`);
				}
			} catch (err) {
				this.snackbar.color = "error";
				this.snackbar.message =
                    "Ocorreu um erro ao atualizar o cadastro.";
				this.$root.$SnackBar.show(this.snackbar);
			}
		}
	},
	watch: {
		"register.movement_type": {
			handler (val) {
				if (val === "INPUT") {
					this.register.destination_system_variable_id = null;
				}
			}
		}
	}
};
</script>
