<template>
  <v-card style="padding: 12px">

    <v-row row justify-space-between>
      <v-col xs="7" md="5">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              large
              color="normal"
              v-on="on"
              @click="toggleFilters"
              v-shortkey="['ctrl', 'f']"
            >
              <v-icon>filter_list</v-icon>
            </v-btn>
          </template>
          <span>Clique para selecionar os filtros</span>
        </v-tooltip>
      </v-col>

      <v-col></v-col>

      <v-col class="text-xs-right d-flex">
        <v-btn large class="flex-one ml-auto" color="primary" @click="openModal">
          Gerar PDF
        </v-btn>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-card
        v-show="expandFilters"
        class="elevation-1 pa-2 px-8"
        :class="importToProject ? 'ml-4 mr-4 mb-4' : 'mb-4'"
      >
        <v-label>Filtrar por Data/Período</v-label>
        <v-row class="align-center pl-3" style="gap: 18px">
          <div>
            <v-menu v-model="datePickerMenu" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="$functions.formatDate(filter.initialDate)"
                  clearable
                  @click:clear="filter.initialDate = null"
                  label="Data Inicial"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="filter.initialDate"
                @change="datePickerMenu = false"
              />
            </v-menu>
          </div>
          <v-label>até</v-label>

          <div>
            <v-menu v-model="endDatePickerMenu" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="$functions.formatDate(filter.endDate)"
                  clearable
                  @click:clear="filter.endDate = null"
                  label="Data Final"
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="filter.endDate"
                @change="endDatePickerMenu = false"
              />
            </v-menu>
          </div>
        </v-row>
      </v-card>
    </v-expand-transition>

    <v-row row md="0">
      <v-col cols="12" md="6">
        <h3 class="text-center pb-2">Entradas</h3>
        <v-data-table
          v-model="inputHoneyTable.selected"
          :headers="inputHoneyTable.headers"
          :items="enhancedItems(inputHoneyTable.items)"
          :loading="inputHoneyTable.loading"
          class="elevation-1"
          item-key="id"
          hide-default-footer
        >
          <template v-slot:[`item.apiary_import_key`]="{ item }">
            <span v-if="!item.isSummary && !!item.isFooter">
              {{ item.apiary ? item.apiary.name : "-" }}
            </span>
          </template>
          
          <template v-slot:[`item.date`]="{ item }">
            <td :class="item.isSummary || item.isFooter ? 'text-left' : ''">
              <span :class="item.isSummary || item.isFooter ? 'font-weight-bold' : ''">
                {{ item.isSummary || item.isFooter ? (item.isFooter ? 'Estoque Atual:' : 'Total Colhido:') : $functions.formatDate(item.date) }}
              </span>
            </td>
          </template>

          <template v-slot:[`item.quantity`]="{ item }">
            <span :class="item.isSummary || item.isFooter ? 'font-weight-bold': ''">
              {{ item.isFooter ? (inputHoneyTable.sumStock - outputHoneyTable.totalSale) : item.quantity | toCurrency }} Kg
            </span>
          </template> 

          <template v-slot:[`item.actions`]="{ item }">
            <span v-if="!item.isSummary && !item.isFooter">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="`honey-appointments/edit/${item.id}`"
                    icon
                    v-on="on"
                  >
                    <v-icon color="#707070">edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="remove(item.id)" icon v-on="on">
                    <v-icon color="#707070">delete</v-icon>
                  </v-btn>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </span>
          </template>
        </v-data-table>

        <div class="text-xs-center pa-4">
          <v-pagination
            v-model="inputHoneyTable.pagination.page"
            :length="inputHoneyTable.pagination.pages"
            v-bind:disabled="inputHoneyTable.disabled"
            :total-visible="inputHoneyTable.pagination.totalVisible"
            prev-icon="arrow_left"
            next-icon="arrow_right"
            primary
          />
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <h3 class="text-center pb-2">Saídas</h3>
        <v-data-table
          v-model="outputHoneyTable.selected"
          :headers="outputHoneyTable.headers"
          :items="enhancedItems(outputHoneyTable.items)"
          :loading="outputHoneyTable.loading"
          class="elevation-1"
          item-key="id"
          hide-default-footer
        >
          <template v-slot:[`item.buyer`]="{ item }">
            <td :class="item.isSummary || item.isFooter ? 'text-right' : ''">
              <span :class="item.isSummary || item.isFooter ? 'font-weight-bold' : ''"> 
                {{ 
                  item.isSummary || item.isFooter ? 
                  (item.isFooter ? 'Estoque Geral de Saídas:' : 'Total Vendido:') : item.buyer }}
              </span>
            </td>
          </template>
          
          <template v-slot:[`item.date`]="{ item }">
            <td>
              <span>
                {{ $functions.formatDate(item.date) }}
              </span>
            </td>
          </template>

          <template v-slot:[`item.quantity`]="{ item }">
            <span :class="item.isSummary || item.isFooter ? 'font-weight-bold': ''">
              {{ item.isSummary ? outputHoneyTable.totalSale : item.quantity | toCurrency }} Kg
            </span>
          </template> 

          <template v-slot:[`item.actions`]="{ item }">
            <span v-if="!item.isSummary && !item.isFooter">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :to="`honey-appointments/edit/${item.id}`"
                    icon
                    v-on="on"
                  >
                    <v-icon color="#707070">edit</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="remove(item.id)" icon v-on="on">
                    <v-icon color="#707070">delete</v-icon>
                  </v-btn>
                </template>
                <span>Excluir</span>
              </v-tooltip>
            </span>
          </template>
        </v-data-table>

        <div class="text-xs-center pa-4">
          <v-pagination
            v-model="outputHoneyTable.pagination.page"
            :length="outputHoneyTable.pagination.pages"
            v-bind:disabled="outputHoneyTable.disabled"
            :total-visible="outputHoneyTable.pagination.totalVisible"
            prev-icon="arrow_left"
            next-icon="arrow_right"
            primary
          />
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="showModal" persistent max-width="400px">
      <v-card>
        <v-card-title class="headline justify-space-between">
          Deseja gerar o Relatório?
          <v-btn icon @click="closeModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p class="text-body-1 mb-4">
            Selecione quais informações deverão ser inseridas no Relatório
          </p>
          <v-switch
            v-model="options.honeyAppointments"
            label="Entrada / Saída de Mel"
            class="ma-0"
          />
          <v-switch
            v-model="options.beeswaxAppointments"
            label="Entrada / Saída de Cera"
            class="ma-0"
          />
        </v-card-text>

        <v-card-actions>
          <v-row class="m-0 p-0" no-gutters>
            <v-col class="px-1">
              <v-btn block medium text color="red" @click="closeModal">
                Cancelar
              </v-btn>
            </v-col>
            <v-col class="px-1">
              <v-btn block medium color="primary" @click="generatePDF">
                Gerar Relatório
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "HoneyAppointments",
  props: {
    supplier: { type: String, default: null },
    apiaryGroup: { type: Number, default: null },
    apiaries: { type: [], default: null },
  },
  data() {
    return {
      expandFilters: false,
      filter: {
        movementType: null,
        initialDate: null,
        endDate: null,
      },
      datePickerMenu: false,
      showModal: false,
      options: {
        beeswaxAppointments: false,
        honeyAppointments: true,
      },
      inputHoneyTable: {
        selected: [],
        sumStock: 0,
        headers: [
          {
            text: "Apiário",
            align: "left",
            value: "apiary.name",
            width: "50%",
          }, // TODO: Confirmar se o apiary está vindo corretamente
          { text: "Data", align: "left", value: "date", width: "15%" },
          {
            text: "Quantidade (Kg)",
            align: "left",
            value: "quantity",
            width: "20%",
          },
          { text: "", align: "right", value: "actions", width: "15%" },
        ],
        items: [],
        loading: false,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,
        pagination: {
          perPage: 5,
          rowsPerPage: 5,
          page: 1,
          total: 0,
          pages: 0,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      outputHoneyTable: {
        totalSale: 0,
        selected: [],
        // TODO: Alterar o campo da coluna Comprador
        headers: [
          { text: "Data", align: "left", value: "date", width: "10%" },
          { text: "Comprador", align: "left", value: "buyer", width: "50%" },
          {
            text: "Quantidade Vendida (Kg)",
            align: "left",
            value: "quantity",
            width: "25%",
          },
          { text: "", align: "right", value: "actions", width: "15%" },
        ],
        items: [],
        loading: false,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,

        pagination: {
          perPage: 5,
          rowsPerPage: 5,
          page: 1,
          total: 0,
          pages: 0,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },

  async mounted() {
    await this.getInputHoneyData();
    await this.getOutputHoneyData();
    // await this.getApiaryImportKey();
  },

  methods: {
    enhancedItems(items) {
      let totalEntrada = 0;
      let totalSaida = 0;

      for (let index = 0; index < items.length; index++) {
        const item = items[index];

        if (item === undefined) {
          continue;
        }

        if (item.movement_type === "INPUT") {
          totalEntrada += item.quantity;
        }
        
        if (item.movement_type === "OUTPUT") {
          totalSaida += item.quantity;
        }
      }

      const totalItem = {
        isSummary: true,
        quantity: totalEntrada,
      };

      const totalStock = {
        isFooter: true,
        quantity: totalSaida,
      };

      this.outputHoneyTable.totalSale = totalSaida;

      return [...items, totalItem, totalStock];
    },

    async getInputHoneyData() {
      try {
        this.inputHoneyTable.loading = true;

        console.log("this.$props.supplier: ", this.$props.supplier);

        const params = {
          page: this.inputHoneyTable.pagination.page,
          perPage: this.inputHoneyTable.pagination.perPage,
          supplierImportKey: this.$props.supplier ? this.$props.supplier : "",
          movementType: "INPUT",
        };
        if (this.filter.initialDate || this.filter.endDate)
          params["date"] = [this.filter.initialDate, this.filter.endDate];

        const res = await this.minamelServices
          .HoneyAppointmentService()
          .get(params);

        this.inputHoneyTable.items = res.data;
        this.inputHoneyTable.pagination.pages = res.meta.last_page;
        this.inputHoneyTable.loading = false;

        for (let index = 0; index < res.data.length; index++) {
          const item = res.data[index];
          this.inputHoneyTable.sumStock += item.quantity;
        }
      } catch (err) {
        this.inputHoneyTable.loading = false;
      }
    },

    async getOutputHoneyData() {
      try {
        this.outputHoneyTable.loading = true;

        const params = {
          page: this.outputHoneyTable.pagination.page,
          perPage: this.outputHoneyTable.pagination.perPage,
          supplierImportKey: this.$props.supplier ? this.$props.supplier : "",
          movementType: "OUTPUT",
        };
        if (this.filter.initialDate || this.filter.endDate)
          params["date"] = [this.filter.initialDate, this.filter.endDate];

        const res = await this.minamelServices
          .HoneyAppointmentService()
          .get(params);

        this.outputHoneyTable.items = res.data;
        this.outputHoneyTable.pagination.pages = res.meta.last_page;

        this.outputHoneyTable.loading = false;
      } catch (err) {
        this.outputHoneyTable.loading = false;
      }
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }
      const res = await this.minamelServices
        .HoneyAppointmentService()
        .delete(id);

      if (res) {
        await this.getInputHoneyData();
        await this.getOutputHoneyData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },

    // async getHoneyAppointment(){
    //   const params = {
    //     apiaries = this.$props.apiaries ? this.$props.apiaries  : ''
    //   }

    //   const res = await this.minamelServices.HoneyAppointmentService()
    // },

    async generatePDF() {
      try {
        this.closeModal();

        const params = {
          supplierImportKey: this.$props.supplier ? this.$props.supplier : "",
          honeyAppointments: this.options.honeyAppointments,
          beeswaxAppointments: this.options.beeswaxAppointments,
        };

        const fileName = `relatorio${
          this.options.honeyAppointments ? "_mel" : ""
        }${this.options.beeswaxAppointments ? "_cera" : ""}.pdf`;

        if (this.filter.initialDate || this.filter.endDate) {
          params["date"] = [this.filter.initialDate, this.filter.endDate];
        }

        const res = await this.minamelServices
          .HoneyAppointmentService()
          .generatePdf(params);

        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        console.log("PDF gerado e baixado com sucesso!");
      } catch (err) {
        console.error("Erro ao gerar o PDF:", err);
        this.$swal.fire({
          allowOutsideClick: true,
          title: "Erro",
          text: "Erro ao tentar gerar o relatório.",
          confirmButtonColor: "#b9041c",
          confirmButtonText: "Voltar",
          icon: "error",
        });
      }
    },
  },
  watch: {
    "inputHoneyTable.pagination.page": function () {
      this.getInputHoneyData();
    },

    "outputHoneyTable.pagination.page": function () {
      this.getOutputHoneyData();
    },

    "filter.initialDate": function () {
      this.getInputHoneyData();
      this.getOutputHoneyData();
    },

    "filter.endDate": function () {
      this.getInputHoneyData();
      this.getOutputHoneyData();
    },
  },
};
</script>
