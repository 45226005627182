<template>
  <v-card elevation="0" :class="!projectId ? 'ma-4' : 'ma-0'">
    <v-toolbar dark color="primary" v-if="projectId && importToProject">
      <v-btn icon dark @click="closeDialog()">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>Importar documentos</v-toolbar-title>
    </v-toolbar>

    <v-row
      row
      justify-space-between
      :class="importToProject ? 'mt-4 ml-4 mr-4' : projectId ? 'ma-0 mt-4' : ''"
    >
      <v-col xs="12" sm="12" md="5">
        <v-text-field
          v-model="filter.term"
          ref="search"
          label="Faça a sua busca"
          clear-icon="close"
          clearable
          append-icon="search"
          @click:append="getData"
          @keyup="getData"
          @keyup.enter="getData"
        />
      </v-col>

      <v-col class="d-flex text-xs-right" style="justify-content: end">
        <v-btn
          v-if="!importToProject"
          color="primary"
          large
          :to="!projectId ? `${routeName}/forms/register` : ''"
          @click="importTo"
          >+ {{ projectId && !importToProject ? "Importar" : "Incluir" }}</v-btn
        >
      </v-col>
    </v-row>

    <v-data-table
      v-model="table.selected"
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      class="elevation-1 scroll"
      :class="!projectId ? 'ma-0' : importToProject ? 'ml-4 mr-4' : ''"
      :show-select="importToProject"
      hide-default-footer
      item-key="id"
    >
      <template v-slot:[`item.name`]="{ item }">
        {{ item.lastForm ? item.lastForm.name : "" }}
      </template>

      <template v-slot:[`item.version_number`]="{ item }">
        <v-chip>
          <span class="primary--text font-weight-bold">
            {{ item.lastForm ? item.lastForm.version_number : "" }}
          </span>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!importToProject && !projectId"
              @click="print(item)"
              icon
              v-on="on"
            >
              <v-icon color="#707070">download</v-icon>
            </v-btn>
          </template>
          <span>Baixar formulário</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!importToProject && !projectId"
              :to="`forms/edit/${item.id}`"
              icon
              v-on="on"
            >
              <v-icon color="#707070">edit</v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="!importToProject"
              @click="remove(item.id)"
              icon
              v-on="on"
            >
              <v-icon color="#707070">clear</v-icon>
            </v-btn>
          </template>
          <span>Excluir</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-xs-center pa-4">
      <v-pagination
        v-model="table.pagination.page"
        :length="table.pagination.pages"
        v-bind:disabled="table.disabled"
        :total-visible="table.pagination.totalVisible"
        prev-icon="arrow_left"
        next-icon="arrow_right"
        primary
      />
    </div>

    <v-divider v-if="importToProject"></v-divider>
    <v-card-actions v-if="importToProject">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        :disabled="!table.selected.length"
        @click="importDataToProject"
        >Importar selecionado(s)</v-btn
      >
      <v-btn text color="error" @click="closeDialog">Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { bus } from "../../../../../../src/main";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Forms",
  props: {
    routeName: { type: String, default: "/documents" },
    projectId: { type: Number, default: null },
    importToProject: { type: Boolean, default: false },
  },
  data() {
    return {
      expandFilters: false,
      filter: {
        term: "",
      },
      dialog: false,
      table: {
        selected: [],
        headers: [
          {
            text: "Nome",
            align: "left",
            value: "name",
          },
          {
            text: "Versão",
            align: "left",
            value: "version_number",
          },
          // {
          // 	text: "Preenchido por",
          // 	align: "left",
          // 	value: "filled_by"
          // },
          {
            text: "",
            align: "right",
            sortable: false,
            value: "actions",
          },
        ],
        items: [],
        loading: false,
        noResultText: this.CONSTANTS.texts.table.noResultText,
        rowPerPageText: this.CONSTANTS.texts.table.rowPerPageText,
        noDataText: this.CONSTANTS.texts.table.noDataText,

        pagination: {
          perPage: !this.importToProject
            ? this.CONSTANTS.integer.table.numberOfRowPerPage
            : 5,
          perPageItems: this.CONSTANTS.arrays.table.itensToView,
          page: 1,
          total: 0,
          pages: 0,
          totalVisible: this.CONSTANTS.integer.table.totalPagesVisible,
        },
      },
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
    };
  },

  created() {
    bus.$on("closeDialog", () => {
      this.dialog = false;
    });
    bus.$on("refreshDocumentList", () => {
      this.getData();
    });
  },

  async mounted() {
    await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.table.loading = true;

        const params = {
          page: this.table.pagination.page,
          perPage: this.table.pagination.perPage,
          term: this.filter.term ? this.filter.term : "",
          projectId: this.importToProject
            ? ""
            : this.projectId
            ? this.projectId
            : "",
          exceptProjectId: this.projectId ? this.projectId : "",
          type: this.importToProject
            ? "RECORD"
            : this.projectId
            ? "PROJECT"
            : "RECORD",
        };

        const res = await this.minamelServices.DocumentService().get(params);

        console.log('res: ', res);

        this.table.items = res.data;
        this.table.pagination.pages = res.meta.last_page;
        this.table.loading = false;
      } catch (err) {
        this.table.loading = false;
      }
    },

    async remove(id) {
      const question = "Deseja realmente excluir este item?";
      const type = "question";

      const confirm = await this.alertConfirm(question, type);

      if (!confirm) {
        return;
      }

      const res = await this.minamelServices.DocumentService().delete(id);

      if (res) {
        await this.getData();
      }
    },

    async alertConfirm(ask, type) {
      let response = await this.$swal.fire({
        title: `${ask}`,
        type: type,
        showCancelButton: true,
        confirmButtonColor: "#4caf50",
        cancelButtonColor: "#ff1744",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        allowOutsideClick: false,
      });

      if (response.value) {
        return true;
      }

      return false;
    },

    async importDataToProject() {
      if (!this.table.selected || this.table.selected.length === 0) {
        return;
      }

      try {
        const dataSet = new Set();

        this.table.selected.forEach((item) => {
          dataSet.add(item.id);
        });

        const data = Array.from(dataSet);

        console.log("data: ", data);
        console.log("this.projectId: ", this.projectId);

        await this.minamelServices
          .DocumentService()
          .importToProject(this.projectId, data);

        this.snackbar.color = "success";
        this.snackbar.message = "Dados importados com sucesso";
        this.$root.$SnackBar.show(this.snackbar);

        this.table.selected = [];

        bus.$emit("refreshDocumentList");
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message =
          "Ocorreu um erro ao importar os dados para o projeto";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async print(form) {
      try {
        const response = await this.minamelServices
          .DocumentService()
          .print(form.id);

        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute(
          "download",
          `${form.lastForm.name.replace(/\//g)}.pdf`
        );

        document.body.appendChild(link);

        link.click();
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message =
          "Ocorreu um erro ao realizar o download do formulário";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    toggleFilters() {
      this.expandFilters = !this.expandFilters;
    },

    importTo() {
      bus.$emit("openDialogDocuments", {
        params: {
          // componentName: "Forms",
          importToProject: false,
        },
      });
    },

    closeDialog() {
      bus.$emit("closeDialogDocuments", { params: {} });
    },
  },

  watch: {
    "table.pagination.page": function () {
      this.getData();
    },
  },
};
</script>
