<template>
  <v-container fluid>
    <v-row row>
      <v-col xs="12">
        <v-subheader>
          <h2>Projeto</h2>
        </v-subheader>
      </v-col>
    </v-row>
    <v-card>
      <v-tabs v-model="tab" show-arrows>
        <v-tabs-slider :color="$vuetify.theme.primary"></v-tabs-slider>
        <v-tab v-on:click="onTabClick(item.name)" :style="{ color: $vuetify.theme.primary }" v-for="(item, i) of tabs"
          :key="i">
          <v-icon color="red" class="mr-2">{{ item.icon }}</v-icon>
          {{ item.name }}
        </v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, i) of tabs" :key="i">
          <v-card v-if="item.name == 'Dados'" class="elevation-0 pa-2">
            <v-form ref="form">
              <v-row row wrap>
                <v-col xs="12" md="12" pa-3>
                  <span class="required grey--text">* Preenchimento obrigatório.</span>
                </v-col>

                <v-col xs="12" md="8" class="pa-3">
                  <v-text-field :rules="[formRules.required]" v-model="register.name" label="Descrição" class="required"
                    ref="name" />
                </v-col>

                <v-col xs="12" md="4" class="pa-3">
                  <v-text-field :rules="[formRules.required]" v-model="register.revision_number"
                    label="Número da revisão" class="required" ref="revision_number" type="text" />
                </v-col>

                <v-col xs="12" md="4" class="pa-3">
                  <v-select class="required" clearable color="primary" chips deletable-chips :items="apiaryGroups"
                    v-model="register.apiary_group_id" item-value="id" item-text="name" label="Grupo de apiários"
                    ref="apiary_group_id" :rules="[formRules.required]"></v-select>
                </v-col>

                <v-col xs="12" md="4" class="pa-3">
                  <v-select class="required" clearable color="primary" chips deletable-chips :items="status"
                    v-model="register.status" item-value="id" item-text="name" label="Status" ref="status"
                    :rules="[formRules.required]"></v-select>
                </v-col>

                <v-col xs="12" md="4" class="pa-3">
                  <v-autocomplete :class="'required'" multiple chips deletable-chips v-model="register.users" clearable
                    :items="users" :loading="isLoading" :search-input.sync="termProject" hide-no-data hide-selected
                    item-text="name" item-value="id" label="Usuários responsáveis"
                    :rules="[formRules.required]"></v-autocomplete>
                </v-col>

                <v-col xs="12" md="12" class="pa-3">
                  <v-textarea rows="1" v-model="register.info" label="Informações" ref="info" />
                </v-col>
              </v-row>
            </v-form>

            <template v-for="btn in dialogButtons">
              <ProjectDuplicate :registerUsers="register.users" @saveProjectBefore="updateBeforeProjectDuplicate"
                :project="register" :projectDates="project_dates" :projectApiary="project_apiary" :key="btn.text"
                v-if="btn.text == 'Duplicar projeto'" ref="ProjectDuplicate" :dialog="btn" />

              <GenerateFiles :registerUsers="register.users" :project="register" :key="btn.text"
                v-if="btn.text == 'Gerar arquivos'" ref="GenerateFiles" :dialog="btn" />

              <ProjectCircleMaps :project="register" :key="btn.text" v-if="btn.text == 'Círculos de mapa'"
                ref="ProjectCircleMaps" :dialog="btn" @updateProjectCircleMaps="(newProjectCircleMaps) => {
                  project_circle_maps = newProjectCircleMaps;
                }
                  " />
            </template>
            <v-card-actions>
              <v-row row wrap>
                <v-col class="text-xs-center text-sm-left" xs="12" sm="6">
                </v-col>
                <v-col class="d-flex ml-auto" sm="6" hidden-xs-only>
                  <v-spacer />
                  <v-btn class="mx-2" style="color: white" color="green" @click="save">
                    Salvar
                  </v-btn>
                  <v-btn color="error" text to="/project">
                    Cancelar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>

          <FieldNotes :routeName="'/project/edit/' + id" :projectId="parseInt(id)" :importToProject="false"
            type="PROJECT" v-if="item.name === 'Caderno de campo'" />

          <Documents :routeName="'/project/edit/' + id" :projectId="parseInt(id)" :importToProject="false"
            type="PROJECT" v-if="item.name === 'Documentos'" />

          <router-view v-if="
            item.name !== 'Dados' &&
            item.name !== 'Documentos' &&
            item.name !== 'Caderno de campo'
          " :projectId="parseInt(id)" :routeName="'/project/edit/' + id"></router-view>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <!-- botoes flutuantes salvar e cancelar -->
    <v-row style="position: fixed; bottom: 15px; right: 90px">
      <v-tooltip v-if="edit" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn style="bottom: -10px" @click="destroy(register)" color="red" small fab hover v-bind="attrs" v-on="on">
            <v-icon color="white">delete</v-icon>
          </v-btn>
        </template>
        <span>Remover</span>
      </v-tooltip>
    </v-row>

    <!-- botões flutuantes dialog -->
    <v-speed-dial style="position: fixed" v-model="dialogButtonfab" bottom right direction="top" show-overlay
      transition="slide-y-reverse-transition">
      <!-- speed dial -->
      <template v-slot:activator>
        <v-btn slot="activator" color="primary" v-model="dialogButtonfab" fab>
          <v-icon>{{ dialogButtonfab ? "close" : "widgets" }}</v-icon>
        </v-btn>
      </template>

      <v-tooltip transition="fab-transition" left v-for="btn in dialogButtons" :key="btn.text">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small :color="btn.color" v-bind="attrs" v-on="on" dark @click="btn.open = toggle(btn.open)">
            <v-icon color="white" dark>{{ btn.icon }}</v-icon>
          </v-btn>
        </template>
        <span>{{ btn.text }}</span>
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<script>
import ProjectDuplicate from "./ProjectDuplicate";
import GenerateFiles from "./GenerateFiles";
import FieldNotes from "../FieldNotes/FieldNotes";
import Documents from "../Documents/Documents";
import ProjectCircleMaps from "./ProjectCircleMaps";

export default {
  name: "RegisterProject",
  components: {
    ProjectDuplicate,
    GenerateFiles,
    FieldNotes,
    Documents,
    ProjectCircleMaps,
  },
  data() {
    return {
      id: this.$route.params.id,
      formRules: {
        required: (v) => !!v || "Campo obrigatório!",
      },
      editApiary: false,

      dialogButtonfab: false,
      tooltipsDialog: false,
      tooltipsDialogDisabled: false,
      tab: null,
      tabs: [
        { name: "Dados", icon: "list" },
        { name: "Apiários", icon: "emoji_nature" },
        { name: "Documentos", icon: "article" },
        { name: "Caderno de campo", icon: "local_library" },
        { name: "Datas e notificações", icon: "notifications" },
        { name: "Anexos", icon: "attach_file" },
      ],
      dialogButtons: [
        {
          text: "Círculos de mapa",
          open: false,
          color: "green",
          icon: "track_changes",
        },
        {
          text: "Duplicar projeto",
          open: false,
          color: "blue",
          icon: "content_copy",
        },
        {
          text: "Gerar arquivos",
          open: false,
          color: "purple",
          icon: "archive",
        },
      ].reverse(),
      project_dates: [],
      project_attachments: [],
      project_apiary: [],
      project_circle_maps: [],
      headerText: "Novo",
      formValid: true,
      status: [
        { id: "P", name: "Pendente" },
        { id: "F", name: "Finalizado" },
      ],
      users: [],
      termProject: "",
      isLoading: false,
      apiaryGroups: [],
      snackbar: {
        message: "",
        visible: false,
        color: "success",
        timeout: 6000,
        x: "right",
        y: "top",
        multiLine: false,
      },
      register: {},
    };
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.headerText = "Editar";

    await this.getUsers();
    await this.getApiaryGroups();

    await this.getData();
  },
  watch: {
    dialogButtonfab(val) {
      this.tooltipsDialog = false;
      this.tooltipsDialogDisabled = false;
      val &&
        setTimeout(() => {
          this.tooltipsDialog = true;

          // eslint-disable-next-line no-mixed-spaces-and-tabs
          this.$nextTick(() => (this.tooltipsDialogDisabled = true));
        }, 250);
    },
    async termProject(val) {
      if (!val) return;

      if (this.isLoading) return;

      try {
        this.isLoading = true;
        const response = await this.minamelServices.UserServiceV2().getAll({ term: val });
        this.users = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },

  methods: {
    onTabClick(selectedTab) {
      if (
        selectedTab.toUpperCase() === "DADOS" &&
        this.$router.currentRoute &&
        this.$router.currentRoute.path
      ) {
        this.$router.push(`/project/edit/${this.id}/data`);
      }

      if (
        selectedTab.toUpperCase() === "CADERNO DE CAMPO" &&
        this.$router.currentRoute &&
        this.$router.currentRoute.path
      ) {
        const currentRoute = this.$router.currentRoute.path;

        if (!currentRoute.includes("honey-appointments")) {
          this.$router.push(`/project/edit/${this.id}/honey-appointments`);
        }
      }

      if (
        selectedTab.toUpperCase() === "APIÁRIOS" &&
        this.$router.currentRoute &&
        this.$router.currentRoute.path
      ) {
        const currentRoute = this.$router.currentRoute.path;

        if (!currentRoute.includes("apiaries")) {
          this.$router.push(`/project/edit/${this.id}/apiaries`);
        }
      }

      if (
        selectedTab.toUpperCase() === "DOCUMENTOS" &&
        this.$router.currentRoute &&
        this.$router.currentRoute.path
      ) {
        const currentRoute = this.$router.currentRoute.path;

        if (!currentRoute.includes("documents")) {
          this.$router.push(`/project/edit/${this.id}/forms`);
        }
      }

      if (
        selectedTab.toUpperCase() === "ANEXOS" &&
        this.$router.currentRoute &&
        this.$router.currentRoute.path
      ) {
        const currentRoute = this.$router.currentRoute.path;

        if (!currentRoute.includes("attachments")) {
          this.$router.push(`/project/edit/${this.id}/attachments`);
        }
      }

      if (
        selectedTab.toUpperCase() === "DATAS E NOTIFICAÇÕES" &&
        this.$router.currentRoute &&
        this.$router.currentRoute.path
      ) {
        const currentRoute = this.$router.currentRoute.path;

        if (!currentRoute.includes("NOTIFICATIONS")) {
          this.$router.push(`/project/edit/${this.id}/notifications`);
        }
      }
    },

    async getApiaryGroups() {
      const response = await this.minamelServices
        .ApiaryGroupServiceV2()
        .getAll({ active: 1 });

      this.apiaryGroups = response.data;
    },

    async getUsers() {
      const response = await this.minamelServices.UserServiceV2().getAll();
      this.users = response.data;
    },

    async getData() {
      const response = await this.minamelServices
        .ProjectServiceV2()
        .show(this.id);

      this.register = response.data;
    },

    alertConfirm(ask) {
      let returnAlert = this.$swal
        .fire({
          title: `${ask}`,
          type: "question",
          showCancelButton: true,
          confirmButtonColor: "#4caf50",
          cancelButtonColor: "#ff1744",
          confirmButtonText: "Confirmar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
        })
        .then((isConfirm) => {
          if (isConfirm.dismiss === "cancel") {
            return false;
          }
          return true;
        });
      return returnAlert;
    },

    toggle(value) {
      if (value) {
        return false;
      } else {
        return true;
      }
    },

    async save() {
      let formValidate = this.$refs.form[0].validate();

      if (!formValidate) {
        this.snackbar.color = "warning";
        this.snackbar.message = "Verifique os campos em destaque.";
        this.$root.$SnackBar.show(this.snackbar);

        this.tab = 0;
        return;
      }

      let alertConfirm = await this.alertConfirm(
        "Salvar as configurações deste Projeto?"
      );

      if (!alertConfirm) {
        return;
      }
      this.update();
    },

    async update() {
      try {
        const response = await this.minamelServices
          .ProjectServiceV2()
          .update(this.id, this.register);

        if (response) {
          this.snackbar.color = "success";
          this.snackbar.message = "Projeto atualizado com sucesso.";
          this.$root.$SnackBar.show(this.snackbar);
        }
      } catch (err) {
        this.snackbar.color = "error";
        this.snackbar.message = "Ocorreu um erro ao salvar o projeto.";
        this.$root.$SnackBar.show(this.snackbar);
      }
    },

    async getProjectApiary(project_id, params = {}) {
      let response = await this.minamelServices
        .ProjectApiaryService()
        .getProjectApiary()
        .search({ project_id, ...params });

      if (!response.error) {
        return response.data;
      }
    },
  },
};
</script>
<style scoped>
.v-date-picker-header.theme--light {
  display: none !important;
}

.v-picker__title.primary {
  display: none !important;
}
</style>
