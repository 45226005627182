/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from "./base/Rest";

/**
 * @typedef {HoneyAppointmentService}
 */
export default class HoneyAppointmentService extends Rest {
  /**
   * @type {String}
   */
  static resource = "honey-appointments/";

  get(params) {
    return super.get(`/?${this.queryStringV2(params)}`);
  }

  show(id) {
    return super.get(`/${id}`);
  }

  showHoney(id) {
    return super.get(`/${id}/show`);
  }

  getHoney(id) {
    return super.get(`/${id}/getHoney`);
  }

  store(data) {
    return super.post("/", data);
  }

  update(id, data) {
    return super.put(`/${id}`, data);
  }

  destroy(id) {
    return super.delete(`/${id}`);
  }

  importToProject(id, data) {
    return super.post(`/${id}/import-project`, data);
  }

  // getApiary(params) {
  //   return super.get("/apiaryImportKey");
  // }

  generatePdf(params) {
    return super.getFile(`/print/pdf/?${this.queryStringV2(params)}`);
  }
}
