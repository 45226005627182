import { render, staticRenderFns } from "./TransitionSystem.vue?vue&type=template&id=8944680e"
import script from "./TransitionSystem.vue?vue&type=script&lang=js"
export * from "./TransitionSystem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports