/* eslint-disable no-mixed-spaces-and-tabs */
import Rest from './base/Rest';

/**
 * @typedef {BeeswaxAppointmentService}
 */
export default class BeeswaxAppointmentService extends Rest {
    /**
	 * @type {String}
	 */
    static resource = 'beeswax-appointments/';

    get (params) {
    	return super.get(`/?${this.queryStringV2(params)}`);
    }

    show (id) {
    	return super.get(`/${id}`);
    }

    store (data) {
    	return super.post('/', data);
    }

    update (id, data) {
    	return super.put(`/${id}`, data);
    }

    destroy (id) {
    	return super.delete(`/${id}`);
    }

    importToProject (id, data) {
    	return super.post(`/${id}/import-project`, data);
    }

    generatePdf (params) {
        return super.getFile(`/print/pdf/?${this.queryStringV2(params)}`);
    }
}
