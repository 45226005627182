import FieldNotes from './FieldNotes.vue';
// import RegisterFieldNotes from './Register.vue';
import HoneyAppointments from "./HoneyAppointments.vue";
import HoneyAppointmentsRegister from "./HoneyAppointmentsRegister.vue";
import CleaningRecords from "./CleaningRecords.vue";
import CleaningRecordsRegister from "./CleaningRecordsRegister.vue";
import BeeSwarmCaptures from "./BeeSwarmCaptures";
import BeeSwarmCapturesRegister from "./BeeSwarmCapturesRegister.vue";
import BeeswaxAppointments from "./BeeswaxAppointments";
import BeeswaxAppointmentsRegister from "./BeeswaxAppointmentsRegister";
import DiseaseTreatmentRecords from "./DiseaseTreatmentRecords";
import DiseaseTreatmentRecordsRegister from "./DiseaseTreatmentRecordsRegister";
import FeedRecords from "./FeedRecords";
import FeedRecordsRegister from "./FeedRecordsRegister";
import SelectGroupFieldNotes from './SelectGroupFieldNotes.vue';
import SelectSupplierFieldNotes from './SelectSupplierFieldNotes.vue';


export default {
	FieldNotes,
	/* RegisterFieldNotes, */
	SelectGroupFieldNotes,
	SelectSupplierFieldNotes,
	HoneyAppointments,
	HoneyAppointmentsRegister,
	CleaningRecords,
	CleaningRecordsRegister,
	BeeSwarmCaptures,
	BeeSwarmCapturesRegister,
	BeeswaxAppointments,
	BeeswaxAppointmentsRegister,
	DiseaseTreatmentRecords,
	DiseaseTreatmentRecordsRegister,
	FeedRecords,
	FeedRecordsRegister
};
